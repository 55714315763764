import React, { useState } from "react";

import { useEthers } from "@usedapp/core";
import { Nft } from "alchemy-sdk";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";

import ConnectWalletButton from "../components/ConnectWalletButton";
import Loading from "../components/Loading";
import collections from "../constants/collections.json";
import useAlchemy from "../hooks/useAlchemy";
import useEarlyAccess from "../hooks/useEarlyAccess";
import { Collection } from "../types";

const Whitelist = () => {
    const { address } = useParams();
    const { account } = useEthers();
    const collection = collections.find(c => c.address == address);

    return (
        <div className={"container mx-auto py-24"}>
            {!collection && <div className={"title"}>Collection Not Found</div>}
            {collection && <Cover collection={collection} />}
            {collection && !account && (
                <div className={"heading text-center mt-16"}>
                    <ConnectWalletButton className={"btn btn-lg"} />
                </div>
            )}
            {collection && account && <MyNFTs account={account} address={collection.address} />}
        </div>
    );
};

const Cover = (props: { collection: Collection }) => {
    return (
        <div className={"flex"}>
            <div className={"w-24 sm:w-48 h-24 sm:h-48 flex-none"}>
                <img
                    src={props.collection.icon}
                    width={"100%"}
                    height={"100%"}
                    className={"rounded-full"}
                    alt={"icon"}
                />
            </div>
            <div className={"ml-12 grow"}>
                <div className={"title"}>{props.collection.name}</div>
                <div className={"body mt-4"}>{props.collection.description}</div>
            </div>
        </div>
    );
};

const MyNFTs = (props: { account: string; address: string }) => {
    const { alchemy } = useAlchemy();
    const [loading, setLoading] = useState(true);
    const [nfts, setNFTs] = useState<Nft[]>();

    useAsyncEffect(async () => {
        try {
            const resp = await alchemy.nft.getNftsForOwner(props.account, {
                contractAddresses: [props.address],
            });
            setNFTs(resp.ownedNfts);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <div>
            <div className={"heading mt-16"}>Your NFTs</div>
            {loading && <Loading />}
            {!loading && nfts?.length == 0 && (
                <div className={"body mt-4"}>You&apos;re not holding any NFTs in this collection.</div>
            )}
            {!loading && nfts && nfts?.length > 0 && <Gallery address={props.address} nfts={nfts} />}
        </div>
    );
};

const Gallery = (props: { address: string; nfts: Nft[] }) => {
    const [loading, setLoading] = useState(true);
    const [whitelisted, setWhitelisted] = useState<string[]>([]);
    const { getWhitelistedNftTokenIds, whitelistNFTs } = useEarlyAccess(props.address);

    useAsyncEffect(async () => {
        try {
            const tokenIds = await getWhitelistedNftTokenIds();
            if (tokenIds) {
                setWhitelisted(tokenIds);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const onWhitelist = (tokenId: string) => async () => {
        await whitelistNFTs([tokenId]);
        setWhitelisted([...whitelisted, tokenId]);
    };

    return (
        <div className="mt-8 grid grid-cols-2 md:grid-cols-4 w-full">
            {loading && <Loading />}
            {!loading &&
                props.nfts.map(nft => (
                    <div key={nft.tokenId} className="w-full mb-8">
                        <NftImage
                            nft={nft}
                            whitelisted={whitelisted.includes(nft.tokenId)}
                            onWhitelist={onWhitelist(nft.tokenId)}
                        />
                    </div>
                ))}
        </div>
    );
};

const NftImage = (props: { nft: Nft; whitelisted: boolean; onWhitelist: () => Promise<void> }) => {
    const [whitelisting, setWhitelisting] = useState(false);
    const onClick = async () => {
        setWhitelisting(true);
        try {
            await props.onWhitelist();
        } finally {
            setWhitelisting(false);
        }
    };
    return (
        <div className="w-full p-2">
            <a
                href={"https://opensea.io/assets/ethereum/" + props.nft.contract.address + "/" + props.nft.tokenId}
                target="_blank"
                rel="noreferrer"
            >
                <img
                    className="inline-block w-full h-auto rounded-lg"
                    src={props.nft.media?.[0]?.gateway || "img/logo.png"}
                    alt={"NFT #" + props.nft.tokenId}
                />
            </a>
            <div className="text-xs md:text-sm text-center mt-2">{props.nft.title || "#" + props.nft.tokenId}</div>
            {props.whitelisted && (
                <button className={"btn btn-clear mt-2 w-full"} disabled={true}>
                    Whitelisted
                </button>
            )}
            {!props.whitelisted && whitelisting && (
                <button className={"btn btn-outline mt-2 w-full"} disabled={true}>
                    Whitelisting...
                </button>
            )}
            {!props.whitelisted && !whitelisting && (
                <button className={"btn mt-2 w-full"} onClick={onClick}>
                    Whitelist
                </button>
            )}
        </div>
    );
};

export default Whitelist;
