import { Alchemy, Network } from "alchemy-sdk";

import { ALCHEMY_API_KEY } from "../constants";

const useAlchemy = () => {
    const alchemy = new Alchemy({
        apiKey: ALCHEMY_API_KEY,
        network: Network.ETH_MAINNET,
    });
    return {
        alchemy,
    };
};

export default useAlchemy;
