import abi from "@levxdao/ve/abis/EarlyAccess.json";
import deployment from "@levxdao/ve/deployments/mainnet/EarlyAccess.json";
import { EarlyAccess } from "@levxdao/ve/typechain/EarlyAccess";
import { useEthers } from "@usedapp/core";
import { Contract, BigNumberish } from "ethers";

const useEarlyAccess = (collection: string) => {
    const { library, account } = useEthers();

    const getWhitelistedNftTokenIds = async (): Promise<string[] | undefined> => {
        if (library && account) {
            const contract = new Contract(deployment.address, abi, library) as EarlyAccess;
            const events = await contract.queryFilter(contract.filters.WhitelistNFT(collection));
            return events.map(event => event.args.tokenId.toString());
        }
    };

    const whitelistNFTs = async (tokenIds: BigNumberish[]) => {
        if (library && account) {
            const contract = new Contract(deployment.address, abi, library.getSigner()) as EarlyAccess;
            const tx = await contract.whitelistNFTs(collection, tokenIds);
            await tx.wait();
        }
    };

    return {
        getWhitelistedNftTokenIds,
        whitelistNFTs,
    };
};

export default useEarlyAccess;
