import React, { useState } from "react";

import { shortenAddress, useEthers, useLookupAddress } from "@usedapp/core";

import ConnectWalletButton from "./ConnectWalletButton";
import ChevronDown from "./icons/ChevronDown";
import ChevronUp from "./icons/ChevronUp";

const AccountButton = () => {
    const { account } = useEthers();
    const [open, setOpen] = useState(false);
    const ens = useLookupAddress();

    const onClick = () => {
        setOpen(!open);
    };

    return account ? (
        <div className={"relative inline-block"}>
            <button className="text-primary text-lg px-2 inline-flex items-center h-12" onClick={onClick}>
                <span className={"text-center font-bold "}>{ens ?? shortenAddress(account)}</span>{" "}
                {open ? <ChevronUp /> : <ChevronDown />}
            </button>
            {open && <DropDown setOpen={setOpen} />}
        </div>
    ) : (
        <ConnectWalletButton onError={alert} />
    );
};

const DropDown = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
    const { deactivate } = useEthers();
    const onDisconnect = () => {
        setOpen(false);
        deactivate();
    };
    return (
        <div className="absolute right-0 z-10 divide-y divide-gray-100 rounded shadow w-44">
            <ul className="border border-white rounded leading-tight py-2 px-3 shadow-light">
                <MenuItem
                    name={"My Account"}
                    onClick={() => {
                        // TODO
                    }}
                />
                <MenuItem name={"Disconnect"} onClick={onDisconnect} />
            </ul>
        </div>
    );
};

const MenuItem = ({ name, onClick }: { name: string; onClick: () => void }) => {
    return (
        <li>
            <button className="text-left w-full px-4 py-2" onClick={onClick}>
                {name}
            </button>
        </li>
    );
};

export default AccountButton;
