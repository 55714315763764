import React from "react";

const Footer = () => {
    return (
        <div className="mt-16">
            <div className="container mx-auto h-32 md:flex items-center justify-between">
                <div className="w-full md:w-1/3">
                    <a href="/#/" className={"flex"}>
                        <div className={"text-base md:text-lg lg:text-xl mt-2 font-bold"}>LEVX.IO</div>
                    </a>
                </div>
                <div className="w-full md:w-2/3 flex justify-end text-xs md:text-sm">
                    <a href={"https://twitter.com/levxapp"} target={"_blank"} className={"mr-4"} rel="noreferrer">
                        TWITTER
                    </a>
                    <a href={"https://discord.gg/oh-geez"} target={"_blank"} className={"mr-4"} rel="noreferrer">
                        DISCORD
                    </a>
                    <a href={"https://github.com/levxdao"} target={"_blank"} className={""} rel="noreferrer">
                        GITHUB
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
