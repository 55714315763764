import React from "react";

import AccountButton from "./AccountButton";

const Header = () => {
    return (
        <div className={"container mx-auto"}>
            <div className={"flex justify-between items-center py-4"}>
                <a className={"flex flex-row items-center"} href={"/"}>
                    <img className={"h-16"} src={"img/logo-horizontal.png"} alt={"logo"} />
                </a>
                <AccountButton />
            </div>
        </div>
    );
};

export default Header;
