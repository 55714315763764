import React from "react";

import { useEthers } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import { ALCHEMY_API_KEY } from "../constants";

const ConnectWalletButton = ({ className, onError }: { className?: string; onError?: (e: string) => void }) => {
    const { isLoading, activate } = useEthers();

    const onClick = async () => {
        const providerOptions = {
            injected: {
                display: {
                    name: "Metamask",
                    description: "Connect with the provider in your browser",
                },
                package: null,
            },
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    rpc: {
                        1: ALCHEMY_API_KEY,
                    },
                },
            },
        };

        const web3Modal = new Web3Modal({
            providerOptions,
        });
        try {
            const provider = await web3Modal.connect();
            await activate(provider);
        } catch (error) {
            const message = (error as Error).message;
            if (message && !message.startsWith("User")) onError?.(message);
        }
    };
    return (
        <div>
            <div className="hidden md:block">
                <button className={className || "btn"} onClick={onClick}>
                    {isLoading ? "Connecting..." : "Connect Wallet"}
                </button>
            </div>
            <div className="block md:hidden">
                <button className={className || "btn"} onClick={onClick}>
                    {isLoading ? "..." : "Connect"}
                </button>
            </div>
        </div>
    );
};

export default ConnectWalletButton;
