import React, { useEffect } from "react";

import { Mainnet, DAppProvider, Config } from "@usedapp/core";
import { providers } from "ethers";
import { HashRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { ALCHEMY_API_KEY } from "./constants";
import Home from "./pages/Home";
import Whitelist from "./pages/Whitelist";

const config: Config = {
    autoConnect: false,
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
        [Mainnet.chainId]: new providers.AlchemyProvider(1, ALCHEMY_API_KEY),
    },
};

function App() {
    return (
        <DAppProvider config={config}>
            <HashRouter basename={process.env.PUBLIC_URL}>
                <ScrollToTop />
                <div>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/whitelist/:address" element={<Whitelist />} />
                        <Route path="*" element={<Navigate to={"/"} replace={true} />} />
                    </Routes>
                    <Footer />
                </div>
            </HashRouter>
        </DAppProvider>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default App;
