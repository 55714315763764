import React from "react";

import ReactLoading from "react-loading";

const Loading = () => (
    <div className={"w-full flex justify-center py-8"}>
        <ReactLoading type={"cubes"} color={"#8C52FF"} height={64} width={64} />
    </div>
);
export default Loading;
