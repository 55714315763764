import React, { useState } from "react";

import { utils } from "ethers";
import { Link } from "react-router-dom";
import useAsyncEffect from "use-async-effect";

import Loading from "../components/Loading";
import collections from "../constants/collections.json";
import { Collection } from "../types";

const Home = () => {
    const { loading, data } = useLoader();

    return (
        <div className={"container mx-auto py-24"}>
            <div className={"title text-center text-primary"}>Whitelist your NFTs to receive THANO$ rewards</div>
            <div className={"mt-16"}>
                <Head />
                {loading && <Loading />}
                {!loading &&
                    data &&
                    data.map((collection, index) => <Row index={index} key={index} collection={collection} />)}
            </div>
        </div>
    );
};

const Head = () => (
    <div className={"flex items-center body"}>
        <div className={"w-1/12 flex-none"}>#</div>
        <div className={"w-11/12 sm:w-1/4 flex-none !font-normal"}>Collection</div>
        <div className={"hidden sm:flex items-center grow"}>
            <div className={"w-1/5 text-right !font-normal"}>Total Supply</div>
            <div className={"w-1/5 text-right !font-normal"}>Holders</div>
            <div className={"w-1/5 text-right !font-normal"}>Floor Price</div>
            <div className={"w-1/5 text-right !font-normal"}>Volume</div>
            <div className={"w-1/5 text-right !font-normal"}>Market Cap</div>
        </div>
    </div>
);

const Row = (props: { index: number; collection: Collection }) => (
    <Link className={"flex items-center pt-6"} to={"/whitelist/" + props.collection.address}>
        <div className={"w-1/12 flex-none"}>{props.index + 1}</div>
        <div className={"w-11/12 sm:w-1/4 flex-none !font-normal pr-4 flex items-center"}>
            <img
                src={props.collection.icon}
                width={40}
                height={40}
                className={"rounded-full inline-block mr-4"}
                alt={"icon"}
            />
            <div>{props.collection.name}</div>
        </div>
        <div className={"hidden sm:flex items-center grow"}>
            <NumberValue value={props.collection.totalSupply} />
            <NumberValue value={props.collection.countOwners} />
            <EtherValue value={props.collection.floorPrice} />
            <EtherValue value={props.collection.volumeAll} />
            <EtherValue value={props.collection.marketCap} />
        </div>
    </Link>
);

const NumberValue = (props: { value?: number }) => (
    <div className={"w-1/5 text-right"}>{props.value ? props.value.toLocaleString() : "N/A"}</div>
);

const EtherValue = (props: { value?: string }) => (
    <div className={"w-1/5 text-right"}>
        <img src={"img/eth.png"} width={16} height={16} alt={"eth"} className={"inline-block mb-1"} />{" "}
        {props.value ? Number(utils.formatEther(props.value)).toFixed(2) : "N/A"}
    </div>
);

const useLoader = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Collection[]>();

    useAsyncEffect(async () => {
        try {
            setData(
                await Promise.all(
                    collections.map(async col => {
                        const res = await fetch(
                            "https://api.looksrare.org/api/v1/collections/stats?address=" + col.address
                        );
                        return {
                            ...col,
                            ...(await res.json()).data,
                        } as Collection;
                    })
                )
            );
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        loading,
        data,
    };
};

export default Home;
